var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item-properties":[
    {
      key: 'logo',
      type: 'logo'
    },
    {
      key: 'name',
      sortable: true
    },
    {
      header: 'label.edit',
      icon: 'mdi-lead-pencil',
      iconClickHandler: defaultProduct => _vm.event.fire('default-product-edit', defaultProduct)
    }
  ],"items":_vm.defaultProductList.items,"search":true,"default-sort-property-key":"name","item-key":"id","no-items-label":"message.noProducts"}})
}
var staticRenderFns = []

export { render, staticRenderFns }