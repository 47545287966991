var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-content-card',{attrs:{"title":"label.subscriptions","fetch":_vm.dashboard.fetch}},[_c('t-table',{attrs:{"items":[_vm.dashboard.subscription],"item-key":"id","item-properties":[
          {
            key: 'free',
            type: 'number'
          },
          {
            key: 'basic',
            type: 'number'
          },
          {
            key: 'custom',
            type: 'number'
          }
        ],"horizontal":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }