var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{ref:"table",attrs:{"default-sort-ascending":false,"item-properties":[
        {
          key: 'key',
          sortable: true
        },
        {
          key: 'version',
          type: 'number',
          sortable: true
        },
        {
          key: 'instances',
          type: 'number',
          sortable: true
        },
        {
          key: 'instancesWithIncident',
          type: 'number',
          icon: 'mdi-refresh',
          iconClickHandler: _vm.camundaActions.retryDefinitionIncidents,
          sortable: true
        },
        {
          header: 'label.diagram',
          icon: 'mdi-sitemap',
          iconClickHandler: definition => _vm.event.fire('camunda-definition-model-view', definition)
        },
        {
          header: 'label.suspension',
          icon: definition => definition.suspended ? 'mdi-play' : 'mdi-pause',
          iconClickHandler: _vm.camundaActions.toggleDefinitionSuspension,
        },
        {
          header: 'label.execute',
          icon: 'mdi-powershell',
          iconClickHandler: _vm.camundaActions.executeDefinition,
        }
      ],"items":_vm.camundaStatus.definitions,"selection-handler":definition => _vm.event.fire('camunda-definition-select', definition),"default-sort-property-key":"instances","item-key":"id"}})
}
var staticRenderFns = []

export { render, staticRenderFns }