var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"title":"label.users","fetch":_vm.userSearch.loader,"actions":[
      {
        icon: 'mdi-file-document-edit',
        handler: () => _vm.event.fire('user-terms-renew-action'),
        tooltip: 'label.renewTermsAndConditions'
      }
    ]}},[_c('user-table',{attrs:{"user-search":_vm.userSearch}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }