var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"title":"label.subscriptions","fetch":_vm.subscriptionList.fetch}},[_c('t-table',{attrs:{"items":_vm.subscriptionList.subscriptions,"item-key":"id","item-properties":[
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'subscriptionDate',
          type: 'date',
          sortable: true
        },
        {
          key: 'plan',
          value: subscriptionPeriod => _vm.$t('label.subscriptionPlan_' + subscriptionPeriod.plan),
          sortable: true
        },
        {
          key: 'nextPlan',
          value: subscriptionPeriod => subscriptionPeriod.nextPlan ? _vm.$t('label.subscriptionPlan_' + subscriptionPeriod.nextPlan) : null,
          sortable: true
        },
        {
          key: 'currentInvoicingBeginDate',
          type: 'date',
          sortable: true
        },
        {
          key: 'currentInvoicingEndDate',
          type: 'date',
          sortable: true
        },
        {
          key: 'currentInvoicingOrders',
          type: 'number',
          sortable: true
        },
        {
          key: 'remainingFreeOrders',
          type: 'number',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: {
            image: 'mdi-lead-pencil',
            clickHandler: subscription => _vm.$dialog.openDialog('subscription', subscription)
          }
        },
      ],"default-sort-property-key":"tenantId","search":true}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }