var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"qr-code","input-fields":[
        {
          key: 'formattedId',
          label: 'label.id',
          type: 'text',
          readOnly: true
        },
        {
          key: 'tenantId',
          type: 'auto-complete-select',
          items: _vm.tenantSearch.items,
          itemKey: 'id',
          itemText: 'name',
          itemFilteringHandler: _vm.tenantSearch.search,
          itemByValueHandler: _vm.tenantSearch.findById,
          readOnly: true
        },
        {
          key: 'name',
          type: 'text',
        },
        {
          key: 'type',
          type: 'select',
          items: _vm.qrCodeType.items,
          itemKey: 'key',
          itemText: 'label'
        }
      ],"item":_vm.qrCode,"save-handler":_vm.qrCode.save}})
}
var staticRenderFns = []

export { render, staticRenderFns }