var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.orderSearch,"item-key":"id","item-properties":[
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'totalAmount',
          type: 'amount',
          sortable: true
        },
        {
          key: 'paymentMethod',
          type: 'text',
          icon: order => {
            switch (order.paymentMethod) {
              case 'TOPUPZ': return {
                image: 't-topupz',
                tooltip: 'label.topupzPayment'
              }
              case 'CASH': return {
                image: 'mdi-account-cash',
                tooltip: 'label.cashPayment'
              }
              case 'BANCONTACT': return {
                image: 't-bancontact',
                tooltip: 'label.bancontactPayment'
              }
            }
          }
        },
        {
          key: 'numberOfProducts',
          type: 'number',
          sortable: true
        },
        {
          header: 'label.details',
          icon: 'mdi-eye',
          iconClickHandler: order => _vm.$dialog.openDialog('order', order)
        }
      ],"search":true,"item-filtering-handler":_vm.orderSearch.search,"default-sort-property-key":"dateTime","default-sort-ascending":false,"no-items-label":"message.noOrders"}})
}
var staticRenderFns = []

export { render, staticRenderFns }