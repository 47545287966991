var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"invoice","action-chips":[
      {
        icon: {
          image: 'mdi-check-decagram',
          color: 'primary'
        },
        label: 'label.approve',
        hideHandler: () => !_vm.invoice.approvable,
        handler: _vm.invoice.approve
      },
      {
        icon: {
          image: 'mdi-check-circle',
          color: 'primary'
        },
        label: 'label.paid',
        hideHandler: () => !_vm.invoice.payable,
        handler: _vm.invoice.markAsPaid
      },
      {
        icon: 'mdi-email-sync-outline',
        label: 'label.resend',
        hideHandler: () => !_vm.invoice.resendable,
        handler: _vm.invoice.resend
      },
      {
        icon: 'mdi-file-upload-outline',
        label: 'label.modifyDocument',
        hideHandler: () => _vm.modifyDocumentHidden,
        handler: _vm.modifyDocumentClicked = true
      },
      {
        icon: 'mdi-file-download-outline',
        label: 'label.downloadDocument',
        hideHandler: () => !_vm.invoice.documentCreated,
        handler: _vm.invoice.downloadDocument
      }
    ],"input-fields":[
      {
        key: 'tenantId',
        label: 'label.tenant',
        type: 'auto-complete-select',
        required: true,
        items: _vm.tenantSearch.items,
        itemKey: 'id',
        itemText: 'name',
        itemFilteringHandler: _vm.tenantSearch.search,
        itemByValueHandler: _vm.tenantSearch.findById,
        readOnlyForEdit: true
      },
      {
        key: 'description',
        type: 'text',
        required: true
      },
      {
        key: 'date',
        type: 'date',
        required: true
      },
      {
        key: 'number',
        type: 'text',
        required: true
      },
      {
        key: 'lines',
        type: 'list',
        required: true,
        uuidItem: true,
        itemKey: 'id',
        inputFields: [
          {
            key: 'description',
            type: 'text',
            required: true
          },
          {
            key: 'price',
            type: 'amount',
            required: true
          },
          {
            key: 'quantity',
            type: 'number',
            required: true
          },
          {
            key: 'amountExcludingVat',
            type: 'amount',
            readOnly: true
          },
          {
            key: 'amountIncludingVat',
            type: 'amount',
            readOnly: true
          }
        ],
        itemText: line => `${line.description} (€${line.price}) X ${line.quantity}`,
        editHandler: _vm.handleInvoiceLineEdit
      },
      {
        key: 'amountExcludingVat',
        type: 'amount',
        readOnly: true
      },
      {
        key: 'amountIncludingVat',
        type: 'amount',
        readOnly: true
      },
      {
        key: 'document',
        type: 'file',
        accept: '.pdf',
        visible: _vm.documentVisible
      }
    ],"item":_vm.invoice,"uuid-item":true,"save-handler":_vm.invoice.save,"delete-handler":_vm.invoice.delete,"open-handler":_vm.handleOnOpen,"edit-handler":_vm.handleInvoiceEdit}})
}
var staticRenderFns = []

export { render, staticRenderFns }