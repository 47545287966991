var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.tenantSearch,"item-key":"id","item-properties":[
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'devices',
          type: 'number',
          sortable: false
        },
        {
          key: 'members',
          type: 'number',
          sortable: false
        },
        {
          key: 'products',
          type: 'number',
          sortable: false
        },
        {
          key: 'cards',
          type: 'number',
          sortable: false
        },
        {
          key: 'unassignedCards',
          type: 'number',
          sortable: false
        },
        {
          header: 'label.edit',
          icon: {
            image: 'mdi-lead-pencil',
            clickHandler: tenant => _vm.$dialog.openDialog('tenant', tenant)
          }
        },
      ],"default-sort-property-key":"name","item-filtering-handler":_vm.tenantSearch.search,"search":true,"no-items-label":"message.noTenants"}})
}
var staticRenderFns = []

export { render, staticRenderFns }