var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"order","input-fields":[
      {
        key: 'tenantId'
      },
      {
        key: 'dateTime',
        type: 'date-time'
      },
      {
        key: 'totalAmount',
        type: 'amount'
      },
      {
        key: 'items',
        label: 'label.products',
        type: 'list',
        itemKey: 'productId',
        itemText: item => `${item.quantity} X ${item.productName} (${_vm.$formatter.formatAmount(item.unitPrice)})`,
        inputFields: []
      }
    ],"item":_vm.order,"open-handler":_vm.onOpen,"read-only-for-edit":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }