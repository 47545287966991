var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"default-product","item":_vm.defaultProduct,"uuid-item":true,"save-handler":_vm.defaultProduct.save,"delete-handler":_vm.defaultProduct.delete,"input-fields":[
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        required: true
      },
      {
        key: 'logo',
        type: 'logo-choice',
        items: _vm.editProductLogos,
        required: true
      }
    ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }