var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"device-registration","input-fields":[
    {
      key: 'id',
      type: 'text',
      label: 'label.serialNumber',
      required: true
    },
    {
      key: 'type',
      type: 'select',
      label: 'label.type',
      items: ['SCANNER', 'CASHIER'],
      required: true
    },
    {
      key: 'model',
      type: 'text',
      label: 'label.model',
      required: true
    },
    {
      key: 'adminEnabled',
      type: 'switch',
      label: 'label.adminEnabled',
      required: true
    }
  ],"item":_vm.device,"save-handler":_vm.device.save,"edit-title":"label.newDevice","new-title":"label.newDevice"}})
}
var staticRenderFns = []

export { render, staticRenderFns }