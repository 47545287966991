var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.deviceSearch,"item-filtering-handler":_vm.deviceSearch.search,"item-key":"id","item-properties":[
        {
          key: 'type',
          type: 'icon',
          icon: device => device.type === 'CASHIER' ? 'mdi-cash-register' : 'mdi-credit-card-scan',
          sortable: true
        },
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'model',
          sortable: true
        },
        {
          key: 'applicationVersion',
          sortable: true
        },
        {
          key: 'adminEnabled',
          type: 'icon',
          icon: device => device.adminEnabled ? { image: 'mdi-arm-flex', color: 'primary' } : null,
          sortable: true
        },
        {
          key: 'online',
          type: 'icon',
          icon: device => device.online ? { image: 'mdi-check-circle', color: 'primary' } : null,
          defaultSortColumn: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: device => _vm.event.fire('device-edit', device)
        }
      ],"search":true,"default-sort-property-key":"online"}})
}
var staticRenderFns = []

export { render, staticRenderFns }