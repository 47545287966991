var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-properties":[
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'date',
          type: 'date',
          sortable: true
        },
        {
          key: 'number',
        },
        {
          key: 'description',
        },
        {
          key: 'amountExcludingVat',
          type: 'amount'
        },
        {
          key: 'amountIncludingVat',
          type: 'amount'
        },
        {
          key: 'approved',
          type: 'icon',
          icon: invoice => invoice.approved ? { image: 'mdi-check-decagram', color: 'primary' } : null,
        },
        {
          key: 'paid',
          type: 'icon',
          icon: invoice => invoice.paid ? { image: 'mdi-check-circle', color: 'primary' } : null,
        },
        {
          key: 'document',
          type: 'icon',
          icon: invoice => invoice.documentCreated ? { image: 'mdi-file-download-outline' } : null,
          iconClickHandler: invoice => invoice.downloadDocument(),
        },
        {
          key: 'edit',
          type: 'icon',
          icon: 'mdi-lead-pencil',
          iconClickHandler: invoice => _vm.$dialog.openDialog('invoice', invoice),
        }
      ],"items":_vm.invoiceSearch,"default-sort-property-key":"date","item-key":"id","item-filtering-handler":_vm.invoiceSearch.search,"search":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }