var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.qrCodeAssignmentSearch.search,"item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'quantity',
          type: 'number',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: qrCodeAssignment => _vm.event.fire('qr-code-assignment-edit', qrCodeAssignment)
        }
      ],"items":_vm.qrCodeAssignmentSearch,"search":true,"default-sort-property-key":"dateTime","item-key":"id","no-items-label":"message.noDataFound"}})
}
var staticRenderFns = []

export { render, staticRenderFns }