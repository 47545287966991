var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"card-order","action-chips":[
      {
        label: 'label.downloadCardsCsv',
        icon: 'mdi-download',
        hideForNew: true,
        handler: _vm.cardOrder.downloadCardsCsv
      }
  ],"copy-properties":['processedDate'],"delete-handler":_vm.cardOrder.delete,"input-fields":[
      {
        key: 'orderDate',
        type: 'date',
        label: 'label.orderDate',
        required: true
      },
      {
        key: 'quantity',
        type: 'number',
        label: 'label.quantity',
        disabledHandler: item => item.quantity && item.quantity > 0,
        required: true,
        minValue: 1
      },
      {
        key: 'provider',
        type: 'text',
        label: 'label.provider'
      },
      {
        key: 'reference',
        type: 'text',
        label: 'label.reference'
      },
      {
        key: 'receivedDate',
        type: 'date',
        label: 'label.receivedDate'
      },
      {
        key: 'csv',
        type: 'file',
        label: 'label.csvFile',
        accept: '.csv', icon: 'mdi-file-delimited', disabledHandler: item => item.processedDate != null
      }
  ],"item":_vm.cardOrder,"save-handler":_vm.cardOrder.save,"uuid-item":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }