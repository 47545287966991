var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item-filtering-handler":_vm.camundaInstanceSearch.search,"item-properties":[
        {
          key: 'businessKey'
        },
        {
          key: 'startTime',
          type: 'timestamp'
        },
        {
          key: 'endTime',
          type: 'timestamp'
        },
        {
          key: 'incidents',
          value: instance => instance.incidents ? instance.incidents.length : 0,
          type: 'number',
          icon: 'mdi-refresh',
          iconClickHandler: _vm.camundaActions.retryInstanceIncidents,
          disabledHandler: instance => !instance.running
        },
        {
          header: 'label.suspension',
          icon: instance => instance.suspended ? 'mdi-play' : 'mdi-pause',
          iconClickHandler: _vm.camundaActions.toggleInstanceSuspension,
          disabledHandler: instance => !instance.running
        },
        {
          header: 'label.details',
          icon: 'mdi-eye',
          iconClickHandler: instance => _vm.event.fire('camunda-instance-view', instance)
        }
      ],"items":_vm.camundaInstanceSearch,"item-key":"id"}})
}
var staticRenderFns = []

export { render, staticRenderFns }