var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"qr-code-assignment","input-fields":[
        {
          key: 'tenantId',
          type: 'auto-complete-select',
          items: _vm.tenantSearch.items,
          itemFilteringHandler: _vm.tenantSearch.search,
          itemByValueHandler: _vm.tenantSearch.findById,
          itemKey: 'id',
          itemText: 'name',
          label: 'label.tenant',
          required: true,
          readOnly: !!_vm.qrCodeAssignment.id
        },
        {
          key: 'qrCodeIds',
          type: 'auto-complete-list',
          label: 'label.qrCodes',
          items: _vm.qrCodeSearch.items,
          itemKey: 'id',
          itemText: 'formattedId',
          itemFilteringHandler: _vm.qrCodeSearch.search,
          itemByValueHandler: _vm.qrCodeSearch.findById,
          required: true,
          visible: !_vm.qrCodeAssignment.id
        },
        {
          key: 'quantity',
          type: 'number',
          visible: !!_vm.qrCodeAssignment.id,
          readOnly: true
        },
        {
          key: 'dateTime',
          type: 'date-time',
          visible: !!_vm.qrCodeAssignment.id,
          readOnly: true
        }
    ],"item":_vm.qrCodeAssignment,"save-handler":_vm.qrCodeAssignment.save,"delete-handler":_vm.qrCodeAssignment.delete,"uuid-item":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }