var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-content-card',{attrs:{"title":"label.devices","fetch":_vm.dashboard.fetch}},[_c('t-table',{attrs:{"items":[_vm.dashboard.device],"item-key":"id","item-properties":[
          {
            key: 'devices',
            type: 'number'
          },
          {
            key: 'unassigned',
            type: 'number'
          },
          {
            key: 'scanners',
            type: 'number'
          },
          {
            key: 'cashiers',
            type: 'number'
          },
          {
            key: 'onlineDevices',
            type: 'number'
          },
          {
            key: 'onlineScanners',
            type: 'number'
          },
          {
            key: 'onlineCashiers',
            type: 'number'
          }
        ],"horizontal":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }