var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.cardAssignmentSearch.search,"item-properties":[
        {
          key: 'dateTime',
          type: 'date',
          sortable: true
        },
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'userId',
          sortable: true
        },
        {
          key: 'numberOfCards',
          type: 'number',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: cardAssignment => _vm.event.fire('card-assignment-edit', cardAssignment)
        }
      ],"items":_vm.cardAssignmentSearch,"search":true,"default-sort-property-key":"dateTime","item-key":"id","no-items-label":"message.noCardAssignments"}})
}
var staticRenderFns = []

export { render, staticRenderFns }